import React from 'react';
import './LandingPage.css';

const FeatureSection = ({ title, description, icon, imageUrl, reverse, bgColor }) => (
  <div className={`feature-section ${reverse ? 'reverse' : ''}`} style={{ backgroundColor: bgColor }}>
    <div className="feature-content">
      <h2>{icon} {title}</h2>
      <p>{description}</p>
    </div>
    <div className="feature-image">
      <img src={process.env.PUBLIC_URL + imageUrl} alt={title} />
    </div>
  </div>
);

const LandingPage = () => {
  const getBackgroundColor = (index) => {
    const colors = ['#070312', '#D32F53', '#070312', '#D32F53'];
    return colors[index % colors.length];
  };

  return (
    <div className="landing-page">
      {/* Beta Banner */}
      <div className="beta-banner">
        The Bijou project has been placed on hold. Thanks for the support!
      </div>

      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-content">
          <div className="logo-pill-container">
            <img src="/bijou_three.png" alt="Bijou Logo" className="logo" />
            <div className="pill">NO ADS. NO NONSENSE.</div>
          </div>
          <h1>Bijou</h1>
          <p className="tagline">Your favourite actor's favourite app.</p>
          <p className="description">Discover thousands of movies and shows, create personalized lists, and stay updated with upcoming and trending releases. Get the latest streaming information and even follow your friends. Welcome to Bijou.</p>

          <div className="app-store-buttons">
            <a href="#" className="app-store-button">
              <img src={process.env.PUBLIC_URL + '/googleplay.png'} alt="Get it on Google Play" />
            </a>
            <a href="#" className="app-store-button">
              <img src={process.env.PUBLIC_URL + '/appstore.png'} alt="Download on the App Store" />
            </a>
          </div>
        </div>
      </div>

      {/* Feature Sections */}
      <div className="features-container">
        {[
          {
            title: "Discover",
            description: "With Bijou, you have the power to explore a vast library of thousands of movies and TV shows, all thanks to The Movie Database. Whether you're looking for an all-time classic or the latest release, Bijou offers detailed overviews, official trailers, and in-depth information on cast and crew. Dive into interesting trivia, browse through season and episode lists for TV shows, and check out ratings from other viewers. Whether you're a casual viewer or a dedicated cinephile, you'll find everything you need to enhance your entertainment journey.",
            icon: "🎬",
            imageUrl: "/discover.png"
          },
          {
            title: "Watchlists",
            description: "Take control of your viewing experience with Bijou's custom watchlists, where you can organize your must-watch items, rate movies and shows, and track everything you've already watched. Whether you want to create a list for future viewing or curate your favorite films and series, Bijou has you covered. Sharing is easy—send your personalized lists directly to friends through the app or website, ensuring they're always up to speed with what's on your radar. You can even compare watchlists to see who's ahead in your movie marathon or TV binge sessions!",
            icon: "📋",
            imageUrl: "/watchlists.png"
          },
          {
            title: "Recommendations",
            description: "Once you've rated a few movies or shows on Bijou, you unlock the power of our tailored recommendation engine. Our bespoke algorithm analyzes your preferences and generates personalized suggestions based on your viewing history. Whether you're in the mood for a certain genre, a particular time period, or something entirely new, just set your preferences and let Bijou do the rest. Watch your custom recommendations come in, opening the door to new favorites and hidden gems you might have otherwise missed!",
            icon: "⭐",
            imageUrl: "/rate.png"
          },
          {
            title: "Profiles",
            description: "Make Bijou truly your own by creating a personalized profile. Showcase your top three movies and shows to give others a glimpse of your tastes, and connect with friends by following their profiles. Not only can you share custom lists with those who follow you, but you can also explore what your friends are watching, what they've rated, and what's on their watchlists. Bijou's social features allow you to stay connected, compare tastes, and even build a network of followers who share your love for film and TV. Your profile becomes a hub for all things entertainment!",
            icon: "🔗",
            imageUrl: "/sharing.png"
          }
        ].map((feature, index) => (
          <FeatureSection
            key={index}
            {...feature}
            reverse={index % 2 !== 0}
            bgColor={getBackgroundColor(index)}
          />
        ))}
      </div>

      {/* Call to Action */}
      <div className="cta-section">
        <h2>What are you waiting for?</h2>
        <p>Download Bijou for free!</p>
        <div className="app-store-buttons">
          <a href="#" className="app-store-button">
            <img src={process.env.PUBLIC_URL + '/googleplay.png'} alt="Get it on Google Play" />
          </a>
          <a href="#" className="app-store-button">
            <img src={process.env.PUBLIC_URL + '/appstore.png'} alt="Download on the App Store" />
          </a>
        </div>
      </div>

      {/* Footer */}
      <footer>
        <p>Made with 💕 for Sophie</p>
        <p>This application uses the The Movie Database API but is not endorsed or certified by TMDb.</p>
        <p>Developed by <a href="https://crtyx.io" target="_blank" rel="noopener noreferrer">[crtyx]</a></p>
        <p>
          <a href="/terms">Terms</a> | 
          <a href="/privacy"> Privacy</a>
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;